import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image/withIEPolyfill'

import Layout from '../../components/layout'
import VideoList from '../../components/video-list'
import WhyNav from '../../components/why-nav'

export default class Overview extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const resources = get(this, 'props.data.allContentfulResource.edges')
    const image = get(this, 'props.data.file.childImageSharp.fixed')
    // weird sorting for intro videos
    const getCh = ({ node }) => {
      if (/Intro \(full video\)/.test(node.title)) return -1
      return +(node.title.match(/(?:Chapter )(\d+)(?::)/) || [, Infinity])[1]
    }
    resources.sort((a, b) => getCh(a) - getCh(b))
    // allow auto meta description
    return (
      <Layout pageTitle={`Why ${siteTitle}?`}>
        <WhyNav path={this.props.path} />
        <Img
          fixed={image}
          alt="4-group Number Blocks and Dice"
          className="float-right d-none d-sm-block m-3"
        />
        <h2>Overview</h2>
        <p>
          At 4-group Math we know that children with a solid foundation in
          number sense find math easy and fun! Without this strong foundation
          many students struggle with math forever.
        </p>
        <p>
          Never again does a child need to be intimidated by math. {siteTitle}{' '}
          Kids love math!
        </p>
        <p>
          4-group Math is unique because it relies on brain-based research,
          utilizing the brain’s innate ability to subitize 4 in a square
          pattern. Our preschool program provides a solid foundation in number
          sense. The 4-group method can also be used to adapt any math program –
          a small modification with a profound effect!
        </p>

        <hr />

        <h2>Introduction</h2>
        <p>Learn the basics of 4-group Math in our Intro video series.</p>
        <VideoList resources={resources} />
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulResource(
      filter: { collections: { eq: "intro" } }
      sort: { fields: slug }
    ) {
      edges {
        node {
          slug
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    file(relativePath: { eq: "why/overview.jpg" }) {
      childImageSharp {
        fixed(width: 284, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
